import React from "react";
import cmile from './cmile.svg'

function Home() {
    return (
        <div>
            <img src={cmile} width="240" className="App-logo" alt="logo" />
            <p>
                Welcome to cmile.net          
            </p>
        </div>
    )
}

export default Home;