import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { runPasswordGen, selectInput, selectPassword, setPasswordGenInput } from "../../redux/password-gen"

const PasswordGen = () => {
    const dispatch = useDispatch()
    const input = useSelector(selectInput)
    const password = useSelector(selectPassword)

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        window.analytics.track('Password Generated');
        console.log('handleSubmit: input', input)
        dispatch(runPasswordGen())
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement >) => {
        // event.preventDefault();
        console.log("handleChange input: ", event.currentTarget.value)
        const formInput = Number(event.currentTarget.value)
        if (!formInput) {
            alert('Password length must be an integer!')
            return
        }
      
        if (formInput > 20) {
            alert('Password too long!')
            return
        }
        dispatch(setPasswordGenInput(formInput))
    }

    return (
        <div>
            <br />
            <br />
            <form onSubmit={handleSubmit}>
            Password Length: <input type='text' onChange={handleChange}/>
            <br />
            <br/><button>Generate</button>
            </form>
            <p>Input: {input}</p>
            <p>Result: {password}</p>
        </div>
    )
}

export default PasswordGen