import { Action, AnyAction } from "redux";
import { RootState } from "./store";
import { createAction, createReducer } from "@reduxjs/toolkit";

interface RecorderState {
    dateStart: string;
    dateEnd: string;
}

const initialRecorderState: RecorderState = {
    dateStart: '',
    dateEnd: '',
}

export const startRecorder = createAction('recorder/start')
export const stopRecorder  = createAction('recorder/stop')

// const START = 'recorder/start';
// const STOP  = 'recorder/stop';

// type StartAction = Action<typeof START>;
// type StopAction  = Action<typeof STOP>;

// export const start = (): StartAction => ({
//     type: START
// });

// export const stop = (): StopAction => ({
//     type: STOP
// });

export const selectRecorderState = (rootState: RootState) => rootState.recorder

export const selectDateStart = (rootState: RootState) => {
    return rootState.recorder.dateStart
}

const recorderReducer = createReducer( initialRecorderState, (builder) => {
    builder.addCase(startRecorder, (state, action) => {
        state.dateStart = new Date().toISOString()
    })
    .addCase(stopRecorder, (state, action) => {
        state.dateStart = ''
    })
})

// const recorderReducer0 = (
//     state: RecorderState = initialRecorderState, 
//     action: StartAction | StopAction
// ) => {
//     switch (action.type) {
//         case START:
//             return {...state, dateStart: new Date().toISOString()}
//         case STOP:
//             return {...state, dateStart: ''}
//         default:
//             return state;
//     }
// }

export default recorderReducer;