import React from "react";
import { Link, Outlet } from "react-router-dom";
import './Header.css'

function Header() {
    return (
        <header>
          <br />
          <nav>
            <ul className="header-ul">
              <li>
                <Link to="/" className="header-link">Home</Link>
              </li>
              <li>
                <Link to="/recorder" className="header-link">Recorder</Link>
              </li>
              <li>
                <Link to="/cpubenchmark" className="header-link">CPU Benchmark</Link>
              </li>
              <li>
                <Link to="/passwordgen" className="header-link">PasswordGen</Link>
              </li>
            </ul>
          </nav>
    
          <Outlet />
        </header>
      )
    }

export default Header;