import { combineReducers } from "redux";
import userEventsReduer from "./user-events";
import recorderReducer from "./recorder";
import cpuBenchmarkReduceer from "./cpu-benchmark";
import { configureStore } from "@reduxjs/toolkit";
import passwordGenReducer from "./password-gen";

const rootReducer = combineReducers({
    userEvents: userEventsReduer,
    recorder: recorderReducer,
    cpubenchmark: cpuBenchmarkReduceer,
    passwordgen: passwordGenReducer,
}
);

// infer type of RootState
export type RootState = ReturnType<typeof rootReducer>;

// const store = createStore(rootReducer);
const store = configureStore({
    reducer: rootReducer
})

export default store;