import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { progressCpuBenchmark, selectDateStart, selectProgress, selectSeconds, startCpuBenchmark, stopCpuBenchmark } from "../../redux/cpu-benchmark";

const CpuBenchmark = () => {
    const dispatch = useDispatch();
    const dateStart = useSelector(selectDateStart)
    const started = dateStart !== '';
    const currentProgress = useSelector(selectProgress)
    const seconds = useSelector(selectSeconds)

    let interval = useRef<number>(0)


    const compute = () => {
        // console.log("compute...")
        // const n_loop = 10000 // dev
        const n_loop = 50000 // production
        var sum = 0

        for (var i=1; i<n_loop; i++) {
            // dispatch(progress())
            for (var x=1; x<1000; x++) {
                const y = 3.141592 * x ** 2
                sum += y
                // console.log( y )
            }
            for (var x=1; x<10000; x++) {
                const y = x / 3.141592
                sum += y
                // console.log( y )
            }
            for (var x=1; x<10000; x++) {
                const y = 3.141592 / x
                sum += y
                // console.log( y )
            }
        }
    }


    const handleClick = () => {
        window.analytics.track('CPU Benchmark Started');
        var crtProgress = 0;
        if (!started) {
            console.log("start clicked")
            window.clearInterval(interval.current)
            // dispatch(start())
            dispatch(startCpuBenchmark())
            interval.current = window.setInterval(() => {
                if (crtProgress < 100) {
                    compute()
                    // dispatch(progress())
                    dispatch(progressCpuBenchmark())
                    crtProgress = crtProgress + 1
                } else {
                    // dispatch(stop())
                    dispatch(stopCpuBenchmark())
                }
            })
        } else {
            console.log("stop clicked")
            // crtProgress = 100
            // dispatch(stop())
            dispatch(stopCpuBenchmark())
        }
    }

    useEffect(() => {
        return () => {
            window.clearInterval(interval.current)
        }
    }, [])

    return (
        <div>
            <h2>CPU Benchmark</h2>
            <button onClick={handleClick} disabled={started}>{started ? "Running..." : "Start..."}</button>
            {/* <p>{interval.current}</p> */}
            <p>Progress: {currentProgress}%</p>
            <p>Seconds: {seconds}</p>

        </div>
    )
}

export default CpuBenchmark;