import React, { useEffect, useRef, useState } from "react";
import "./Recorder.css"
import { useDispatch, useSelector } from "react-redux";
import { selectDateStart, startRecorder, stopRecorder } from "../../redux/recorder";
import cx from 'classnames'

const addZero = (n: number) => n < 10 ? `0${n}` : `${n}`

const Recorder = () => {
    const dispatch = useDispatch();
    const dateStart = useSelector(selectDateStart)
    const started = dateStart !== '';

    let interval = useRef<number>(0)
    // const [count, setCount] = useState<number>(0)
    const [, setCount] = useState<number>(0)

    const handleClick = () => {
        if (!started) {
            window.clearInterval(interval.current)
            window.analytics.track('Recorder Started');
            // dispatch(start())
            dispatch(startRecorder())
            interval.current = window.setInterval(() => {
                setCount(count => count + 1) // use a function to get prev value
            }, 1000)
        } else {
            window.analytics.track('Recorder Stopped');
            // dispatch(stop())
            dispatch(stopRecorder())
        }
    }

    // clean up when component unmount, to prevent memory leak
    useEffect(() => {
        return () => {
            window.clearInterval(interval.current)
        }
    }, [])

    let seconds = started 
        ? Math.floor((Date.now() - new Date(dateStart).getTime()) / 1000)
        : 0;
    let hours = seconds ? Math.floor(seconds/60/60): 0
    seconds -= hours * 60 * 60
    let minutes = seconds ? Math.floor(seconds/60): 0
    seconds -= minutes * 60


    return (
        // <div className={started ? "recorder-started" : "recorder"}>
        <div className={cx('recorder', {'recorder-started': started})}>
            <button className="recorder-record" onClick={handleClick}><span></span></button>
            <div className="recoder-counter">
                {addZero(hours)}:{addZero(minutes)}:{addZero(seconds)}
            </div>
        </div>
    );
};

export default Recorder;

