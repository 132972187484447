import { createAction, createReducer } from "@reduxjs/toolkit";
import { RootState } from "./store";

interface PasswordGenState {
    input: number;
    password: string;
}

const initialPasswordGenState: PasswordGenState = {
    input: 0,
    password: '12345678'
}

export const selectPassword = (rootState: RootState) => rootState.passwordgen.password
export const selectInput = (rootState: RootState) => rootState.passwordgen.input

export const setPasswordGenInput = createAction<number>('passwordgen/setinput')
export const runPasswordGen = createAction('passwordgen/run')

const passwordGenReducer = createReducer(initialPasswordGenState, (builder) => {
    builder
    .addCase(setPasswordGenInput, (state, action) => {
        state.input = action.payload
        // state.password = 'input: ' + action.payload
    })
    .addCase(runPasswordGen, (state, action) => {
        var result = '';
        var array = Array(Number(state.input));
        var i;
        var type;
        for (i of array) {
          type = Math.floor(Math.random() * 5)
          switch(type) {        
            case 0: // number
              result = result + String.fromCharCode(48 + Math.floor(Math.random() * 10));
              break;
            case 1: // upper case
              result = result + String.fromCharCode(65 + Math.floor(Math.random() * 26));
              break;
            default: // lower case
              result = result + String.fromCharCode(97 + Math.floor(Math.random() * 26));
          }      
        }
    
        state.password = result
    })
})

export default passwordGenReducer;