import { Action } from "redux";
import { RootState } from "./store";
import { createAction, createReducer } from "@reduxjs/toolkit";

interface CpuBenchmarkState {
    dateStart: string;
    dateEnd: string;
    currentProgress: number;
    seconds: number;
}

const initialCpuBenchMarkState: CpuBenchmarkState = {
    dateStart: '',
    dateEnd: '',
    currentProgress: 0,
    seconds: 0,
}

// const START = 'cpubenchmark/start';
// const STOP  = 'cpubenchmark/stop';
// const PROGRESS = 'cpubenchmark/progress';

// type StartAction = Action<typeof START>;
// type StopAction  = Action<typeof STOP>;
// type ProgressAction  = Action<typeof PROGRESS>;

// export const start = (): StartAction => ({
//     type: START
// });

// export const stop = (): StopAction => ({
//     type: STOP
// });

// export const progress = (): ProgressAction => ({
//     type: PROGRESS
// });

export const selectCpuBenchmarkState = (rootState: RootState) => rootState.cpubenchmark

export const selectDateStart = (rootState: RootState) => rootState.cpubenchmark.dateStart

export const selectProgress = (rootState: RootState) => rootState.cpubenchmark.currentProgress

export const selectSeconds = (rootState: RootState) => rootState.cpubenchmark.seconds

export const startCpuBenchmark = createAction('cpubenchmark/start')
export const stopCpuBenchmark = createAction('cpubenchmark/stop')
export const progressCpuBenchmark = createAction('cpubenchmark/progress')

// export const progressCpuBenchmark = createAction(
//     'cpubenchmark/progress',
//     () => {
//         return {payload: {}}
//     }
// )


const cpuBenchmarkReduceer = createReducer(initialCpuBenchMarkState, (builder) =>{
    builder.addCase(startCpuBenchmark, (state, action) => {
        state.dateStart = new Date().toISOString()
        state.currentProgress = 0
    })
    .addCase(stopCpuBenchmark, (state, action) => {
        state.dateStart = ''
    })
    .addCase(progressCpuBenchmark, (state, action) => {
        state.currentProgress++;
        state.seconds = Math.round((Date.now() - new Date(state.dateStart).getTime()) / 10) / 100
    })
})

// const cpuBenchmarkReduceer0 = (
//     state: CpuBenchmarkState = initialCpuBenchMarkState,
//     action: StartAction | StopAction | ProgressAction
// ) => {
//     switch(action.type) {
//         case START:
//             return {...state, dateStart: new Date().toISOString(), currentProgress: 0}
//         case STOP:
//             return {...state, dateStart: ''}
//         case PROGRESS:
//             // console.log('reducer.progress', state)
//             return {...state, 
//                 currentProgress: state.currentProgress+1, 
//                 seconds: Math.round((Date.now() - new Date(state.dateStart).getTime()) / 10) / 100
//             }
//         default:
//             return state;
//     }

// }

export default cpuBenchmarkReduceer;