import React from 'react';
// import logo from './logo.svg';
import './App.css';
import Recorder from './Recorder';
import CpuBenchmark from './CpuBenchmark';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Home from './Home';
import Header from './Header';
import PasswordGen from './PasswordGen';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Header />}>
            <Route index element={<Home />} />
            <Route path='recorder' element={<Recorder />} />
            <Route path='cpubenchmark' element={<CpuBenchmark />} />
            <Route path='passwordgen' element={<PasswordGen />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
